import { BehaviorSubject, Observable } from 'rxjs'

export type AWRxStoreDataMap = { [key: string]: BehaviorSubject<any> };

class AWRxBasicStore {
  private subjectMap: AWRxStoreDataMap = {};

  getObservable(subjectKey: string): Observable<any> {
    if(!this.subjectMap[subjectKey]){
      this.subjectMap[subjectKey] = new BehaviorSubject<any>(null);
    }
    return this.subjectMap[subjectKey];
  }

  pushDataToSubject(subjectKey: string, data: any) {
    if(!this.subjectMap[subjectKey]){
      this.subjectMap[subjectKey] = new BehaviorSubject<any>(null);
    }
    this.subjectMap[subjectKey].next(data);
  }

  clearData(subjectKey: string) {
    delete this.subjectMap[subjectKey];
  }
}

export default AWRxBasicStore;