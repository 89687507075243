import AWRxBasicStore from './rx-basic-store';
import AWRxCollectionStore from './rx-collection-store';

const basicStores: { [storeName: string]: AWRxBasicStore } = {};
const collectionStores: { [storeName: string]: AWRxCollectionStore } = {};

const AWRxStoreFactory = {

  getBasicStore: (storeName: string): AWRxBasicStore => {
    if(!basicStores[storeName]){
      basicStores[storeName] = new AWRxBasicStore();
    }
    return basicStores[storeName];
  },

  getCollectionStore: (storeName: string, collectionMaxSize?: number): AWRxCollectionStore => {
    if(!collectionStores[storeName]){
      collectionStores[storeName] = new AWRxCollectionStore(collectionMaxSize);
    }
    return collectionStores[storeName];
  }
}

export default AWRxStoreFactory;